import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Toggle from '../../components/ui/Toggle';

const IndexPage = () => (
  <Layout rtl>
    <SEO title="طرق الدفع" />
    <h2>طرق الدفع</h2>
    <Toggle title="ما هي طرق الدفع المتاحة؟">
      <p>يمكنكم الاختيار إحدى طرق الدفع العديدة، التي تشمل بطاقات فيزا وماستر كارد والدفع بواسطة آبل Apple Pay ، بالإضافة إلى بطاقات خصم مدى  كما يمكنكم اختيار طريقة الدفع نقداً عند التوصيل، والتي تخضع لرسوم إضافية تبلغ 20 ريال سعودي.</p>
    </Toggle>
    <Toggle title="هل تشمل أسعار المنتجات ضريبة القيمة المضافة؟">
      <p>نعم، تشمل أسعار جميع منتجاتنا ضريبة القيمة المضافة بنسبة15 %</p>
    </Toggle>
    <Toggle title=" لم ينجح طلبي، هل هناك مشكلة؟">
      <p>نأسف لما حدث، ولكن لم يتم استلام طلبكم لعدم إمكانية معالجة تفاصيل الدفع الخاصة بكم. لتقديم طلب جديد، يمكنكم التحقق من التفاصيل
        التالية لضمان نجاح الطلب:</p>
      <ul>
        <li> التحقق من دقة بيانات بطاقة الدفع المحفوظة في حسابكم، والتي قد تشمل الاسم أو تاريخ انتهاء صلاحية البطاقة</li>
        <li>التحقق من إدخال رمز الأمان الصحيح، وهو الرمز المكون من ثلاثة أرقام على الوجه الخلفي للبطاقة</li>
        <li><span dir="ltr">800 440 0099</span> لمزيد من الاستفسارات، يرجى التواصل معنا على الرقم </li>
      </ul>
    </Toggle>
    <h2>الطلبات</h2>
    <Toggle title="كيف لي أن أعرف أن طلبي تم بنجاح؟">
      <p>بمجرد بلوغكم صفحة الشكر على موقعنا مع تفاصيل تأكيد طلبكم، فهذا يعني أنه تم إتمام الطلب بنجاح. سنرسل لكم أيضاً رسالة إلكترونية تؤكد
        وصول طلبكم</p>
    </Toggle>
    <Toggle title="كيف يمكنني الاطلاع على حالة طلبي؟">
      <p>ما عليكم سوى تسجيل الدخول إلى حسابكم على تطبيق ليم لمراجعة حالة طلباتكم وغيرها من المعلومات في قسم "حسابي".</p>
    </Toggle>
    <Toggle title="ما الذي يُعتبر دليلاً على الشراء لطلبي عبر الإنترنت؟">
      <p>بمجرد توصيل طلبكم أو استلامه، ستصلكم فاتورة ضريبية بالمنتجات التي تم شراؤها تتضمن تفاصيل الطلب والدفع. يمكن استخدام الفاتورة كدليل
        على الشراء خلال أي اتصالات مستقبلية.</p>
    </Toggle>
    <Toggle title="هل يمكنني إلغاء طلبي؟">
      <p>نعم ولكن فقط خلال إعداده لدينا. يمكنكم التواصل معنا على الرقم <span dir="ltr">800 440 0099</span>. أوقات دوامنا من السبت إلى الخميس (العطلة يوم الجمعة)، من
        الساعة 9 صباحاً حتى 6 مساءً (بتوقيت الخليج القياسي).
      </p>
      <p>إذا كان طلبكم لدى شركة الشحن السريع الشريكة لنا، يمكنكم الاستفادة من سياسة إعادة المشتريات مجاناً الخاصة بنا، والتي تسمح بإعادة السلع خلال 15 يوماً من شرائها.</p>
    </Toggle>
    <Toggle title="لماذا تم إلغاء طلبي؟">
 <p>نأسف لما حدث، ولكن إذا تم إلغاء طلبكم أو جزء منه، فربما يكون سبب ذلك عدم قدرتنا على تأكيد تفاصيل الدفع الخاصة بكم أو بسبب مشاكل فنية أو قد تكون السلعة المطلوبة تالفة. إذا كنتم قد دفعتم مقابل الطلب الذي تم إلغاؤه، سنقوم تلقائياً بإرجاع المبلغ المدفوع. في حال كنتم تحتاجون إلى مساعدة أو لديكم أي تساؤلات، يمكنكم التواصل معنا على الرقم <span dir="ltr">800 440 0099</span></p>
    </Toggle>
    <h2>حسابي</h2>
    <Toggle title="كيف يمكنني إنشاء حساب؟">
      <p>يمكنكم إنشاء حساب معنا بكل سهولة، عبر صفحة الاشتراك الخاصة بنا والنقر على زر إنشاء حسابي. وبمجرد الاشتراك، سوف نتحقق من تفاصيل الاتصال الخاصة بكم لحماية أمن بياناتكم وتقديم أفضل تجربة ممكنة.</p>
    </Toggle>
    <Toggle title="كيف يمكنني تغيير كلمة المرور الخاصة بحسابي؟">
      <p>يمكنكم تسجيل الدخول إلى حسابكم على تطبيق ليم، واختيار تغيير كلمة المرور في استعراض معلومات الحساب، لاستبدال كلمة المرور الحالية وإنشاء كلمة مرور جديدة. في حال نسيتم كلمة المرور، يمكنكم الضغط على زر "هل نسيت كلمة المرور؟" في صفحة تسجيل الدخول للحصول على تعليمات إعادة ضبط كلمة المرور</p>
    </Toggle>
    <Toggle title="هل يمكنني تغيير عنوان بريدي الإلكتروني؟">
      <p>بمجرد إنشاء حسابكم، يمكنكم اختيار "معلومات شخصية" وتحديث عنوان بريدكم الإلكتروني. اضغطوا على زر "حفظ"، ليتم تحديث عنوان بريدكم الإلكتروني لأي اتصالات مستقبلية</p>
    </Toggle>
    <Toggle title="هل يمكنني تغيير رقم هاتفي المسجل؟">
      <p>بمجرد إنشاء حسابكم، يمكنكم اختيار "معلومات شخصية" وتحديث رقم هاتفكم. ستتلقون رسالة نصية للتحقق من رقم هاتفكم الجديد</p>
    </Toggle>
    <Toggle title="كيف أقوم بإضافة أو تحرير معلومات التوصيل الخاصة بي؟">
      <p>يمكنكم تسجيل الدخول إلى حسابكم واختيار "سجلّ العناوين" في "استعراض معلومات الحساب" لإضافة معلومات توصيل جديدة أو تحديث المعلومات القديمة أو حذفها، بالإضافة إلى تعيين عنوان افتراضي لطلبكم التالي.</p>
    </Toggle>
    <Toggle title="ما التفاصيل التي يمكنني الاطلاع عليها في سجل الطلبات؟">
      <p>يتيح لكم قسم سجل الطلبات في "حسابي على تطبيق ليم" التحقق من حالة طلباتكم الحالية والوصول إلى فواتيركم الضريبية وطباعتها.</p>
    </Toggle>
    <h2>نبذة عنا</h2>
   <p>ليم. مفهوم جديد للأزياء المحتشمة
     منذ عام 2018، أبدعنا العديد من تشكيلات الموضة المحافظة والراقية بلمسة عصرية، عُرضت في أبرز المجلات مثل فوغ وElle وهاربرز بازار وEmirates Woman.
     يمكنكم تسوق أرقى الموضة المحافظة بأسعار معقولة عبر متاجر ليم المنتشرة في جميع أنحاء المملكة العربية السعودية والإمارات العربية المتحدة والبحرين.

     أو يمكنكم الطلب عبر تطبيق ليم، الذي يوفر خدمة التوصيل داخل المملكة العربية السعودية مع الشحن إلى جميع أنحاء العالم قريبًا.
   </p>
    <p><strong>متاجر العلامة:</strong></p>
    <p>المملكة العربية السعودية:</p>
    <p>حياة مول، الرياض</p>
    <p>الرياض بارك مول، الرياض</p>
    <p>بانوراما مول، الرياض</p>
    <p><strong>الإمارات العربية المتحدة:</strong></p>
    <p>ميركاتو مول، دبي</p>
    <p>مردف سيتي مول، دبي</p>
    <p><strong>البحرين:</strong></p>
    <p>مول سيتي سنتر البحرين، المنامة</p>
  </Layout>
);

export default IndexPage
